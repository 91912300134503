
.form--subscribe {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  &,a {
    color: #fff;
  }
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }

  h1, h2 {
    margin-top: 0;
  }

  p {
    margin-bottom: 30px;
  }

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .form__item {
    flex: 0 0 100%;
    max-width: 100%;
    +.form__item {
      margin-top: 50px;
    }
  }

  .form__item--wrapper {
    background: #eeeeee;
    border-radius: 5px;
    text-align: center;
    padding: 20px 50px;
    &,h2 {
      color: #000;
    }
    a {
      color: #5678ac;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    button {
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  .form__radio-item {
    background: #fff;
    color: #7d7d7d;
    padding: 5px 20px;
    border-radius: 5px;

    +.form__radio-item {
      margin-top: 15px;
    }

    .form__radio-control label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      font-weight: 300;
      cursor: pointer;
    }

    .button-state {
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 1px solid;
      position: relative;
    }

    input {
      position: absolute;
      visibility: hidden;
    }

    input:checked + span:after {
      content: "";
      height: 8px;
      width: 8px;
      background: #db2827;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .form-control {
    height: 45px;
    line-height: 46px;
    border-radius: 5px;
    font-size: 14px;
  }

  @include breakpoint-sm {
    .form__item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 45px;

      +.form__item {
        margin-top: 0;
      }
    }
  }

}
