.tooltip-inner {
  background-color:#333f42;
  padding: 20px;
  font-size: 16px;
  line-height: 28px;
  max-width: 40%;
}
.tooltip.right {
  margin-left: 10px;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #333f42;
  left:-5px;
  border-width: 15px 15px 15px 0;
  margin-top: 0;
  transform: translateY(-50%);
}
.container--max-950 {
  max-width: 950px !important;
  width: 100% !important;
  margin: 0 auto;
}
